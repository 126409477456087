export default {
  data() {
    return {
      language: "",
      languageOption: [],
      languageOptionSelect: "",
      optionMapping: {
        English: {
          value: "English",
          label: "EN",
        },
        Chinese: {
          value: "Chinese",
          label: "简",
        },
        TraditionalChinese: {
          value: "TraditionalChinese",
          label: "繁",
        },
      },
    };
  },
  methods: {
    checkLanguage() {
      const Language = localStorage.getItem("Language");
      if (Language === "Chinese") {
        this.language = "Chinese";
      } else if (Language === "English") {
        this.language = "English";
      } else if (Language === "TraditionalChinese") {
        this.language = "TraditionalChinese";
      } else {
        this.language = "English";
      }
      this.$emit("ChangeLanguage", this.language);
      this.languageOptionSelect = this.optionMapping[Language];
    },

    languageChange(language) {
      localStorage.setItem("Language", language.value);
      window.Lark.ServiceAjax.setInvokeAttr({
        Language: language.value,
      });
      location.reload();
    },
  },
  beforeMount() {
    this.checkLanguage();

    this.$proSmart.setting.getCommonLoginSetting(this).then((b) => {
      for (let i = 0; i < b.languageOption.length; i++) {
        this.languageOption.push(this.optionMapping[b.languageOption[i]]);
      }

      let checkLanguage = this.languageOption.filter(
        (b) => b.value === this.languageOptionSelect.value
      );

      if (checkLanguage.length === 0) {
        this.languageOptionSelect = this.languageOption[0];
        this.languageChange(this.languageOption[0]);
      }
    });
  },
};
