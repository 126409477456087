<template>
  <div
    style="overflow: auto; height: 100%; padding: 0px !important"
    class="main-container"
  >
    <q-toolbar class="justify-between Homepage_Header">
      <img class="Homepage_CompanyLogo" :src="headLogo" />
      <div
        style="display: flex; margin-right: 20px"
        v-if="languageOption.length > 1"
      >
        <div
          v-for="(language, index) in languageOption"
          :key="index"
          :class="
            languageOptionSelect.value === language.value
              ? 'language-link'
              : 'language-link language-link-no-active'
          "
        >
          <span class="label" @click="languageChange(language)">
            {{ language.label }}
          </span>
          <span
            v-if="index !== languageOption.length - 1"
            style="padding-right: 5px; padding-left: 5px"
            >|</span
          >
        </div>
      </div>
    </q-toolbar>
    <div style="padding: 0 20px">
      <div style="overflow: auto">
        <!-- <pro-document-breadcrumbs
          :breadcrumbs="breadcrumbs"
          user-role="guest"
        /> -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import MultipleLanguageMixin from "@/mixins/multipleLanguage";

export default {
  // components: { ProDocumentBreadcrumbs },
  mixins: [MultipleLanguageMixin],
  data() {
    return {
      // breadcrumbs: [],
      headLogo: "",
    };
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.updateBreadcrumbs(to);
  //   next();
  // },
  methods: {
    // updateBreadcrumbs(route) {
    //   /** @type {[{name: string, icon?: string, to?: {name: string}}]} */
    //   const arr = [
    //     {
    //       name: "menu.GuestPortal",
    //       icon: "PROSmart-DocumentBox",
    //     },
    //   ];

    //   if (route.name !== "GuestPortal") {
    //     arr.push({ name: route.meta.title });
    //   }
    //   this.breadcrumbs = arr;
    // },
    getLogoImage() {
      this.invokeService(
        "PCSystem",
        "GetIamgeSetting",
        [],
        (msg) => {
          let hostURL = window.Lark.ServiceAjax.getHost();

          if (
            msg.ReturnData.$.PCheadlogo != "" &&
            typeof msg.ReturnData.$.PCheadlogo != "undefined"
          ) {
            this.headLogo =
              hostURL +
              "/Handlers/CommonBinary.ashx?dataID=" +
              msg.ReturnData.$.PCheadlogo;
          } else {
            this.headLogo = "./static/image/desktop-login/companylogo.png";
          }
        },
        () => {
          this.headLogo = "./static/image/desktop-login/companylogo.png";
        }
      );
    },
  },
  created() {
    // this.updateBreadcrumbs(this.$route);
    this.getLogoImage();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";
.Homepage_CompanyLogo {
  margin-left: 8px;
  width: 150px;
  height: 45px;
  object-fit: scale-down;
  vertical-align: middle;
}
.language-link {
  display: flex;
}

.language-link .label {
  cursor: pointer;
}

.language-link-no-active .label {
  color: $unread;
  text-decoration: underline;
}
</style>
